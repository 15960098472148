<script setup lang="ts">
import appConfig from "~/app.config";
import { routes } from "~/constants";
import { pushEventDataLayer } from "~/utils/datalayer";
const route = useRoute();
const { isMobile, isIos } = useDevice();
const { t } = useI18n();

const isCandidates = computed(() => {
  return route.path === routes.candidatUrl.index;
});

const isEtab = computed(() => {
  return route.path === routes.recruteurUrl.index;
});

const isBlog = computed(() => {
  return route.path === routes.blogHomeUrl;
});

const showAppBanner = computed(() => {
  return (isCandidates.value || isEtab.value || isBlog) && isMobile;
});

const location = computed(() => {
  if (isEtab.value) return "etab";
  if (isBlog.value) return "blog";
  return "candidat";
});

const typeUser = computed(() => {
  const user = isEtab.value
    ? t("COMPONENTS.APP_BANNER.@ETAB")
    : t("COMPONENTS.APP_BANNER.@CANDIDATE");
  return user;
});

const appStoreUrl = computed(() => {
  const user = isEtab.value ? "etab" : "candidates";
  const store = isIos ? "appleStore" : "playStore";
  const url = appConfig.applications[store][user];
  return url;
});

const isVisible = ref(true);

const hideBanner = () => {
  isVisible.value = false;
  localStorage.setItem("bannerVisible", "false");
};

onMounted(() => {
  const storedVisibility = localStorage.getItem("bannerVisible");
  isVisible.value = storedVisibility !== "false";
});
</script>

<template>
  <div v-if="showAppBanner" class="app-banner" :class="{ active: isVisible }">
    <div class="app-banner-close" @click="hideBanner">
      <i role="presentation" class="extra-close"></i>
    </div>
    <div class="app-banner-infos">
      <NuxtImg
        class="app-banner--icon"
        src="/img/candidats/icon-appli-candidat.png"
        alt="logo app extracadabra"
        title="Logo de l'app extracadabra"
      />
      <div class="app-banner-infos-right">
        <span class="app-banner-infos-right--text">Application {{ typeUser }}</span>
        <NuxtImg
          class="app-banner-infos-right--stars"
          src="/img/candidats/stars.png"
          alt="stars"
          title="Note Extracadabra"
        />
      </div>
    </div>
    <NuxtLink
      :to="appStoreUrl"
      target="_blank"
      class="app-banner-cta"
      rel="nofollow"
      @click="pushEventDataLayer('download_app_banner', 'www_download_app', location)"
    >
      Installer
    </NuxtLink>
  </div>
</template>

<style lang="scss" scoped>
.app-banner {
  position: fixed;
  top: 0;
  height: 0;
  display: flex;
  align-items: center;
  padding: 0 var(--showcase-size-075) 0 0;
  z-index: 20;
  background-color: var(--showcase-color-additional-light);
  transition: all ease-in-out 0.3s;
  &.active {
    height: 70px;
    transition: all ease-in-out 0.3s;
    width: 100%;
  }
  &-close {
    min-width: 55px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .extra-close {
    width: 21px;
    height: 21px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 30px;
    background-color: var(--showcase-color-secondary-500);
    &:before {
      font-size: 11px;
    }
  }
  &--icon {
    max-width: 45px;
    max-height: 45px;
  }
  &-infos {
    display: flex;
    align-items: center;
    margin: 0 0 0 0;
    &-right {
      display: flex;
      flex-wrap: wrap;
      margin-left: var(--showcase-size-075);
      &--text {
        font-size: 13px;
        color: var(--showcase-color-secondary-300);
        margin-bottom: var(--showcase-size-050);
        width: 100%;
      }
      &--stars {
        max-width: 57px;
      }
    }
  }
  &-cta {
    background-color: var(--showcase-color-primary-200);
    color: var(--showcase-color-additional-light);
    padding: var(--showcase-size-050) var(--showcase-size-100);
    border-radius: 20px;
    font-size: 13px;
    text-decoration: none;
  }
}
</style>
